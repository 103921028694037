.cms-pagination {
	display         : flex;
	justify-content : end;
	align-items     : center;

	--active-color: oklch(0 0 0 / 5%);

	a {
		color           : inherit;
		text-decoration : none;
		border-radius   : 3px;
		transition: background-color 0.2s ease-in-out;

		&:hover {
			background-color: var(--active-color);
		}
	}

	> * {
		padding: 0.25rem 0.75rem;
	}

	.pagination-pages {
		list-style      : none;
		margin          : 0;
		display         : flex;
		align-items     : center;
		gap             : 0.25rem;
		overflow        : auto;
		scrollbar-width : none;     // Firefox

		&::-webkit-scrollbar {
			display : none;  // Safari and Chrome
		}

		a {
			padding: 0.25rem;
		}

		.active a {
			background-color: var(--active-color);
		}
	}

	&.full {
		display               : grid;
		grid-template-columns : min-content auto min-content;
	}
}
